import $ from 'jquery';

const Bases = {

	// 공통 변수 모음
	breakPointMobile: 768,
	focusSelector: '',

	// 공통 함수
	// 레이어팝업 열기
	layerOpen(target) {
		var el = $(target);
		el.removeClass('is-hidden').addClass('is-open');
		$('body').addClass('layer-opens');

		el.find('.js-close').off().on('click', function() {
			el.removeClass('is-open').addClass('is-hidden');
			if( $('.layer.is-open').length < 1 ){
				$('body').removeClass('layer-opens');
			}
		});
	},

	// 레이어팝업 닫기
	layerClose(target) {
		var el = $(target);
		el.removeClass('is-open').addClass('is-hidden');
		if( $('.layer.is-open').length < 1 ){
			$('body').removeClass('layer-opens');
		}
		return false;
	},

	// 경고 팝업 출력
	layerConfirm(text, callback) {
		const $body = $('body');
		const langConfirmText = ($('html').attr('lang') === 'ko') ? '확인' : 'OK';
		$body.append(`
			<div class="layer layer_confirm is-open">
				<div class="layer_inner">
					<button type="button" class="close js-close">close</button>
					<div class="top_icon"></div>
					<div class="message">${text}</div>
					<div class="button_wrap">
						<button type="button" class="button primary js-confirm">${langConfirmText}</button>
					</div>
				</div>
			</div>
			`);
		var $layer = $('.layer_confirm');
		$body.addClass('layer-opens');
		$layer.removeClass('is-hidden').addClass('is-open');

		setTimeout(function(){
			$layer.find('.js-confirm').trigger('focus');
		},50);
		$layer.find('.js-confirm').off().on('click', function(){
			if (callback instanceof Function){
				callback();
			}else if( typeof callback === 'string' || typeof callback === 'object' ){
				common.layerFocus(callback);
			}
			layerClose();
		});
		$layer.find('.js-close').off().on('click', function() {
			if (callback instanceof Function){
				callback();
			}else if( typeof callback === 'string' || typeof callback === 'object' ){
				common.layerFocus(callback);
			}
			layerClose();
		});
		function layerClose(){
			$layer.remove();
			if ($('.layer.is-open').length < 1) {
				$body.removeClass('layer-opens');
			}
		}
	},

	// 포커스
	layerFocus(target){
		$(target).focus();
		var $focus = Bases.focusSelector;
		if( typeof $focus === 'string') $focus = $(target);
		var header = $('.header').outerHeight();
		var top = 0;
		if($focus && $focus.length > 0){
			var scrollTop = $(window).scrollTop();
			var windowHeight = $(window).innerHeight();
			top = $focus.offset().top - (header + 30);
			$focus.addClass('focus');
			setTimeout(function(){$focus.focus();}, 100);
			if (top < scrollTop || top > scrollTop + windowHeight) {
				$('html').animate({scrollTop: top}, 150);
			}
		}
	},

	// pagination
	pages: {
		goFirst(){
			const pageNum = $('.pagination .page');
			pageNum.first().addClass('active').siblings().removeClass('active');
		},
		goLast(){
			const pageNum = $('.pagination .page');
			pageNum.last().addClass('active').siblings().removeClass('active');
		},
		goPage(element){
			const el = $(element).parent();
			el.addClass('active').siblings().removeClass('active');
		}
	}
}

// 정규식 정리
const regExp = {
	
}

export { Bases, regExp }