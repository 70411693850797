import 'core-js/stable';
import $ from 'jquery';
import { Bases } from './bases';

function SelectCustom(selector) {
	this.selector = $(selector);
	this.init();
	this.initEvent();
}
SelectCustom.prototype = {
	init: function () {
		const _this = this;
		_this.create();
	},
	initEvent: function () {},
	create: function (target) {
		if (target !== undefined) this.selector = $(target);
		// console.log(this.selector);
		// select option 값 가져오기, value 가 none 인건 사용하지 않는값
		const options = this.selector
			.find('option')
			.map(function () {
				if ($(this).val() !== 'none') return { value: $(this).val(), text: $(this).text() };
			})
			.get();
		var selected = this.selector.find('option:selected');
		var value = selected.val();
		var current = selected.text();

		// console.log(value, current);

		// var str;
		// if ($('.share-list').length > 0) str = '<div class="select-box diff_size">';
		// else str = '<div class="select-box">';
		// str += '<button type="button" class="current">' + current + '</button>';
		// str += '<div class="option-dropdown">';
		// options.map(function (v) {
		// 	const active = v.value === value ? ' selected' : '';
		// 	str += '<button type="button" class="item' + active + '" value="' + v.value + '">' + v.text + '</button>';
		// });
		// str += '</div>';
		// str += '</div>';
		// this.selector.after(str);
		// this.selector.addClass('hide');
	},
	change: function (target, value) {
		this.selector = $(target);
		this.selector.val(value);
		this.selector.next().remove();
		const _this = this;
		_this.create();
	},
	listSelected: function (target) {
		const button = $(target);
		button.addClass('selected').siblings().removeClass('selected');
		button.parent().prev('.current').text(button.text());
		button.closest('.select-box').removeClass('is-active').prev().val(button.val()).trigger('change');
	},
	listShown: function (target) {
		const selectBox = $(target).closest('.select-box');
		if (selectBox.hasClass('is-active')) {
			selectBox.removeClass('is-active');
		} else {
			$('.select-box').removeClass('is-active');
			selectBox.addClass('is-active');
			var scroll = selectBox.find('.option-dropdown');
			var scrollTop = scroll.scrollTop();
			var top = selectBox.find('.selected').position().top;
			scroll.animate({ scrollTop: scrollTop + top + 1 }, 0);
		}
	},
};
const jsSelectBox = $('select.js-select');
let SelectList;
jsSelectBox.each(function () {
	SelectList = new SelectCustom($(this));
});
$(document).on('click', '.select-box .option-dropdown .item', function () {
	SelectList.listSelected($(this));
});
$(document).on('click', '.select-box .current', function () {
	SelectList.listShown($(this));
});
$(document).on('click', function (e) {
	var target = $(e.target);
	if (!target.hasClass('select-box') && !target.parents().hasClass('select-box')) {
		$('.select-box').removeClass('is-active');
	}
});

let DEV = window.DEV || {};
window.DEV = (($) => {
	'use strict';

	const common = {
		imageChange(selector) {
			$(selector).each((idx, el) => {
				const desktopSrc = $(el).data('image-desktop');
				const mobileSrc = $(el).data('image-mobile');
				const tag = $(el).prop('tagName');
				if (tag === 'IMG') {
					if (window.innerWidth <= Bases.breakPointMobile) {
						$(el).attr('src', mobileSrc).addClass('shown');
					} else {
						$(el).attr('src', desktopSrc);
					}
				} else {
					if (window.innerWidth <= Bases.breakPointMobile) {
						$(el)
							.css('background-image', 'url(' + mobileSrc + ')')
							.addClass('shown');
					} else {
						$(el).css('background-image', 'url(' + desktopSrc + ')');
					}
				}
			});
		},

		numberInputCheck(target) {
			target.value = target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
		},

		pagination: function () {
			$(document).on('click', '.pagination .num', function () {
				$(this).addClass('is-current').siblings().removeClass('is-current');
			});
			$(document).on('click', '.pagination .prev', function () {
				var current = $('.pagination .is-current');
				var pageNum = $('.pagination .num');
				if (current.text() !== pageNum.first().text()) {
					pageNum.removeClass('is-current');
					current.prev('.num').addClass('is-current');
				}
			});
			$(document).on('click', '.pagination .next', function () {
				var current = $('.pagination .is-current');
				var pageNum = $('.pagination .num');
				if (current.text() !== pageNum.last().text()) {
					pageNum.removeClass('is-current');
					current.next('.num').addClass('is-current');
				}
			});
		},

		hoverEmphasis: function () {
			const $noticeList = $('.notice_list');
			const $item = $noticeList.find('.item');
			$item.on('mouseenter', function (e) {
				const target = $(e.currentTarget);
				target.siblings().removeClass('emphasis');
				target.addClass('emphasis');
			});

			$noticeList.on('mouseleave', function () {
				$item.removeClass('emphasis');
				$item.eq(0).addClass('emphasis');
			});
		},

		init() {
			common.pagination();
			$(window).on('scroll', function () {
				$('header').css('left', 0 - $(this).scrollLeft());
			});

			if ($('.main_header').length > 0) {
				common.hoverEmphasis();

				$(window).on('scroll', function () {
					if ($(document).scrollTop() < 10) {
						$('.main_header').removeClass('scroll');
					} else {
						$('.main_header').addClass('scroll');
					}
				});
			}
		},
	};

	const nav = {
		navScroll() {
			const header = $('header');
			const scrollTop = $(window).scrollTop();
			const standard = header.outerHeight();
			if (scrollTop > standard) {
				header.addClass('is-scroll');
			} else {
				header.removeClass('is-scroll');
			}

			const footer = $('footer');
			const footerTop = footer.offset().top;
			const footerHeight = footer.outerHeight();
			const windowHeight = window.innerHeight;
			const btnTalk = $('.btn_talk');
			let margin = '20px';
			if (window.innerWidth <= Bases.breakPointMobile) margin = '2.7778vw';
			// console.log(footerTop, scrollTop, windowHeight)
			if (footerTop > scrollTop + windowHeight) {
				btnTalk.attr('style', '');
			} else {
				btnTalk.css({ position: 'absolute', bottom: `calc( ${footerHeight}px + ${margin}` });
			}
		},
		topButton() {
			const button = $('.button_top');
			button.on('click', function () {
				$('html').animate({ scrollTop: 0 }, 300);
			});
			function posBtn() {
				let scrollTop = $(window).scrollTop();
				const footer = $('footer');
				const footerTop = footer.offset().top;
				const footerHeight = footer.outerHeight();
				const winWidth = window.innerWidth;
				const winHeight = window.innerHeight;
				const content = 1400;
				const margin = 100; // 버튼넓이 + 여백 40
				let right = (winWidth - content) / 2 - margin;
				if (right < 0) right = 0;
				button.css({ right: right });
				if (scrollTop < 10) {
					button.removeClass('show');
				} else {
					button.addClass('show');
					// console.log(scrollTop, winHeight, footer);
					if (scrollTop + winHeight < footerTop - 40 + 60) {
						button.css({
							position: 'fixed',
							bottom: '40px',
						});
					} else {
						button.css({
							position: 'absolute',
							bottom: footerHeight + 40,
						});
					}
				}
			}
			posBtn();
			$(window).resize(function () {
				posBtn();
			});
			$(window).scroll(function () {
				posBtn();
			});
		},
		familyLink() {
			const target = $('footer .select_box');
			const current = target.find('.current');
			const list = target.find('.option-dropdown');
			current.on('click', function () {
				if (target.hasClass('is-active')) {
					target.removeClass('is-active');
				} else {
					target.addClass('is-active');
				}
			});
			$(document).on('click', function (e) {
				var target = $(e.target);
				if (!target.hasClass('select_box') && !target.parents().hasClass('select_box')) {
					$('footer .select_box').removeClass('is-active');
				}
			});
		},
		init() {
			if ($('.button_top').length > 0) {
				nav.topButton();
			}
			nav.familyLink();
		},
	};

	const motion = {
		motion: function () {
			$('.is-motion').each(function () {
				let top = $(this).offset().top;
				let scrollTop = $(window).scrollTop();
				let windowHeight = $(window).height();
				let gap = 0.15;
				if ($('.about__intro').length > 0) gap = 0.25;
				if ($(this).closest('.vr_layer').length > 0) {
					const content = $(this).closest('.vr_layer').find('.contents');
					scrollTop = content.scrollTop();
					windowHeight = content.outerHeight();
					top = top - content.prev().outerHeight();
				}
				// console.log(scrollTop)
				if (scrollTop > Math.floor(top - (windowHeight - windowHeight * gap))) {
					$(this).addClass('on');
				}
				// else if (scrollTop < top) {
				// 	$(this).removeClass('on');
				// }
			});
		},

		init: function () {
			$(window).on('scroll', function () {});
		},
	};

	const layer = {
		layerOpen: function (target) {
			const el = $(target);
			el.removeClass('is-hidden').addClass('is-open');
			$('body').addClass('layer-opens');

			el.find('.js-close')
				.off()
				.on('click', function () {
					el.removeClass('is-open').addClass('is-hidden');
					if ($('.layer.is-open').length < 1) {
						$('body').removeClass('layer-opens');
					}
				});
		},

		layerClose: function (target) {
			const el = $(target);
			el.removeClass('is-open').addClass('is-hidden');
			if ($('.layer.is-open').length < 1) {
				$('body').removeClass('layer-opens');
			}
			return false;
		},

		init: function () {
			layer.layerOpen();
			layer.layerClose();
		},
	};

	const tab = {
		tabMove: function (tabSection) {
			$(tabSection).each(function () {
				let $button = $(this).find('.job_tab');
				let $contents = $(this).find('.job_section');

				//탭안에 탭 있는경우
				if (tabSection === '.tab_inner') {
					$button = $(this).find('.job_section_title');
					$contents = $(this).find('.job_section_bottom');
				}

				$button.on('click', function (e) {
					e.preventDefault();
					$(this).addClass('is-active').siblings().removeClass('is-active');

					const tabId = $(this).attr('id');
					$contents.removeClass('on');
					$('.' + tabId).addClass('on');
				});
			});
		},

		init: function () {
			if ($('.tab_contents').length > 0) {
				tab.tabMove('.tab_contents');
			}
			if ($('.tab_inner').length > 0) {
				tab.tabMove('.tab_inner');
			}
		},
	};

	//question
	const question = {
		questionOpen(element){
			console.log(el);
			const el = $(element).parent();
			if( el.hasClass('is-active')){
				el.removeClass('is-active').addClass('is-hidden');
			}else{
				el.addClass('is-active').removeClass('is-hidden');
			}
		},

		init(){
			if($('.guide_questions').length > 0) {
				question.questionOpen('.guide_questions .questions__list', 0);
			}
		}
	};

	$(document).ready(function () {
		common.init();
		nav.init();
		motion.init();
		layer.init();
		tab.init();
		question.init();
	});

	return {
		motion: motion.motion,
		layerOpen: layer.layerOpen,
		layerClose: layer.layerClose,
		numberInputCheck: common.numberInputCheck,
		questionOpen: question.questionOpen,
	};
})($);
